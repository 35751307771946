import merge from 'lodash/merge';
import {
  UPDATE_CART_PRODUCT_GALLERY,
  UPDATE_CART_PRODUCT_PRICING,
  APP_MOUNT,
  UPDATE_CART_PRODUCT_CONFIGURATION,
  UPDATE_CART_PRODUCT,
} from '../actions/actionTypes';

export const initialState = {
  configuration: {
    cart: [],
    items: [],
    deliveryTime: {},
  },
  media: {},
  fulfillment: {},
  fulfillmentSelection: '',
  attributes: {}
};

export default (state = initialState, action) => {
  const media = merge(
    {},
    { media: state.media },
    { media: action.media }
  );
  switch (action.type) {
  case UPDATE_CART_PRODUCT_GALLERY:
    return {
      ...state,
      ...media,
    };
  case UPDATE_CART_PRODUCT_PRICING:
    // This is used specifically after the correct price is calculated
    // We need this to quick fix paypal issue and we will come back later for a more elegant fix
    return {
      ...state,
      pricing: { ...state.pricing, ...action.data },
      configuratorPricing: { ...action.data }
    };
  case UPDATE_CART_PRODUCT_CONFIGURATION:
    // Need to keep the promos. Quick fix for the time being.
    if (state.configuratorPricing) {
      // eslint-disable-next-line no-param-reassign
      action.data.product.pricing.value = state.configuratorPricing.value;
    }
    return {
      ...state,
      ...action.data.product,
    };
  case UPDATE_CART_PRODUCT:
    if (state.configuratorPricing) {
      // eslint-disable-next-line no-param-reassign
      action.data.product.pricing.value = state.configuratorPricing.value;
    }
    return {
      ...state,
      ...action.data.product,
    };
  case APP_MOUNT: {
    return {
      ...initialState,
    };
  }
  default:
    return state;
  }
};
