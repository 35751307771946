import { takeLatest, select, call, put } from 'redux-saga/effects';
import forEach from 'lodash/forEach';
import merge from 'lodash/merge';
import safeSaga from '../safeSaga';
import { UPDATE_GCC_PRODUCT_DETAILS_SUCCESS, UPDATE_CART_PRODUCT } from '../../actions/actionTypes';
import addConfigurationToCartV2 from '../../actions/cartActions/addConfigurationToCartV2';
import {
  productDetailsSelector,
  cartProductSelector,
} from '../../selectors';

import { getSpecialOrderDeliveryTime } from '../../api/eleanorApi';
import { updateSODeliveryTime } from '../../actions/deliveryActions';
import { LIFE_CYCLE_EVENT_CONFIGURATOR_DATA, LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING } from '../../actions/hydration/constants';

function* getDeliveryTime() {
  const { siteId, partnerProductId } = yield select(productDetailsSelector);
  const product = yield select(cartProductSelector);
  let deliveryTime = {};
  let productionLeadTime = null;

  try {
    if (siteId && partnerProductId) {
      const response = yield call(getSpecialOrderDeliveryTime, siteId, partnerProductId);
      const fulfillmentOptions = product?.fulfillment?.fulfillmentOptions;
      const shippingObj = fulfillmentOptions ? { ...fulfillmentOptions } : null;
      const { startDate, endDate } = response;
      forEach(shippingObj, (o) => {
        o.services.find((d) => {
          if (d?.deliveryDates) {
            // eslint-disable-next-line no-param-reassign
            d.deliveryDates.startDate = startDate?.split('T')[0];
            // eslint-disable-next-line no-param-reassign
            d.deliveryDates.endDate = endDate?.split('T')[0];
          }

          return d;
        });
      });

      const configuration = {
        deliveryTime: {
          startDate: startDate.split('T')[0],
          endDate: endDate.split('T')[0],
        },
        cart: product?.configuration?.cart,
        items: product?.configuration?.items
      };

      const parsedDeliveryData = merge(
        {},
        { product },
        { product: { configuration } },
      );

      LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_DATA, { configuratorData: parsedDeliveryData });
      deliveryTime = {
        startDate: new Date(response.startDate),
        endDate: new Date(response.endDate),
      };
      productionLeadTime = response.productionLeadTimeInDays;

      yield put({
        type: UPDATE_CART_PRODUCT,
        data: parsedDeliveryData,
      });
      const cart = product?.configuration?.cart;
      if ((cart && cart?.length === 0) || !cart?.[0]?.itemId) {
        yield put(addConfigurationToCartV2());
      }
    }
  } finally {
    LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, false);
    yield put(updateSODeliveryTime(deliveryTime, productionLeadTime));
  }
}

export default function* deliveryTimeSaga() {
  yield takeLatest(UPDATE_GCC_PRODUCT_DETAILS_SUCCESS, safeSaga(getDeliveryTime));
}
