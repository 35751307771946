import {
  select,
  put,
  call,
} from 'redux-saga/effects';
import { updateHarmonyPrice } from '../../actions/parseHarmony';
import {
  updatePricing,
  clearPricing,
  getPricingFromThd,
  getPricingMsrp,
  getPricingSO,
  getPricingError,
  setPricingAvailable,
} from '../../actions/pricingActions';
import {
  configuratorSelector,
  skuMapSelector,
  thdStoreInfoSelector,
  configSelector,
  productDetailsAttributeSelector,
} from '../../selectors';
import getThdProductDetailsAndPricing from './getThdProductDetailsAndPricing';
import getMsrpPricing from './getMsrpPricing';
import getSOPricing from './getSOPricing';
import {
  hasError,
} from './_utils';
import {
  CUSTOM_CONFIG_BASED,
} from '../../utils/pipFiguratorType';
import {
  CONFIGURATOR,
  LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING,
  LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE,
} from '../../actions/hydration/constants';
import {
  setHydrationComplete,
} from '../../actions/hydration/orchestration';
import { harmonyProductData } from '../common/pricing/harmonyProductData';
import { configuratorPricingLifeCycleEventBusTrigger } from '../common/pricing/configuratorPricingLifeCycleEventBus';
import isDraftProduct from '../../utils/isDraftProduct';
import getOmsIdFromUrl from '../../utils/getOmsIdFromUrl';
import { updateProductGallery } from '../../actions/getGallery';

const omsIdFromUrl = getOmsIdFromUrl;

export default function* getPricing() {
  const configurator = yield select(configuratorSelector);
  const config = yield select(configSelector);
  const {
    storeNumber,
  } = yield select(thdStoreInfoSelector);
  const skuMap = yield select(skuMapSelector);
  const {
    options,
    choices,
  } = yield select(configuratorSelector);
  const {
    bypassSkuMap,
    pipfiguratorType,
    isMajorAppliance,
  } = yield select(productDetailsAttributeSelector);

  if (hasError(options)) {
    yield put(clearPricing());
    LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE, { configuratorUnavailable: true });
    LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, false);
    return;
  }

  let path;
  try {
    if (skuMap.skuType === 'HomeDepot_OMSID') {
      yield put(getPricingFromThd());
      path = getThdProductDetailsAndPricing;
    } else if ((bypassSkuMap && bypassSkuMap === 'True')
      || (pipfiguratorType && pipfiguratorType === CUSTOM_CONFIG_BASED)) {
      yield put(getPricingMsrp());
      path = getMsrpPricing;
    } else if (skuMap.isSpecialOrder) {
      yield put(getPricingSO());
      path = getSOPricing;
    }
    const args = {
      skuMap,
      options,
      choices
    };
    const updatedPricing = yield call(path, args);
    yield put(updateProductGallery());
    const { isSpecialBuy, originalPrice, specialPrice } = updatedPricing;
    const specialBuy = isSpecialBuy ? 'Special Buys' : null;
    const discount = originalPrice - specialPrice;
    const percent = (discount / originalPrice) * 100;
    const someSkusHaveZeroPrice = updatedPricing?.products?.some((x) => x?.pricing?.originalPrice === 0);

    const partnerProductId = isDraftProduct ? omsIdFromUrl : config?.partnerProductId?.toString();
    const propProductData = harmonyProductData(originalPrice, discount, percent, specialBuy, specialPrice, someSkusHaveZeroPrice, partnerProductId, pipfiguratorType);
    configuratorPricingLifeCycleEventBusTrigger(propProductData);

    LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE, { configuratorUnavailable: !originalPrice && !specialPrice });
    LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, false);
    yield put(updateHarmonyPrice(isSpecialBuy, originalPrice, specialPrice));
    yield put(setPricingAvailable());
    yield put(setHydrationComplete(CONFIGURATOR));
  } catch (err) {

    LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE, { configuratorUnavailable: true });
    LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, false);
    yield put(clearPricing());
    yield put(getPricingError(err));
  }
}
